@import '../variables';
//////////////////////////////////////////
// Table
//////////////////////////////////////////

.table {
  thead {
    th {
      padding: 0.5rem;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1.13px;
      color: #8798ad;
      border-top: 0;
      border-bottom: 1px solid #1a2b48;

      .dropdown-link {
        padding: 0;
        color: #8798ad;
        background-color: transparent;
        border: 0;
        &::after {
          bottom: 1px;
          width: 8px;
          vertical-align: baseline;
          content: url(../../assets/images/chevron-down-yellow.svg);
        }
      }

      .custom-control {
        min-height: 0;
        line-height: 19px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0.5rem;
        font-size: 14px;
        border-top: 1px solid #1a2b48;
        vertical-align: inherit; // optimal, dont change

        @include media-breakpoint-down(lg) {
          font-size: 13px;
        }

        &.td-circle {
          width: 12px;
        }
        &.td-edit {
          width: 40px;
        }
        &.td-expand {
          width: 40px;
          cursor: pointer;
          @include media-breakpoint-up(lg) {
            width: 130px;
          }

          .text {
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      &.inactive {
        td {
          opacity: 0.2;
          &:first-child,
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  &.clickable {
    tbody {
      tr {
        position: relative;
        cursor: pointer;
        &:hover {
          background-color: #a1b4cb19;
          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;

            border-top: 19.5px solid transparent;
            border-bottom: 19.5px solid transparent;
            border-right: 15px solid #a1b4cb19;
          }
        }
      }
    }
  }

  // Sorting
  th.sortable {
    position: relative;
    &.asc:before,
    &.desc:before {
      display: none;
    }

    &.asc:after,
    &.desc:after {
      content: '';
      display: inline-block;
      margin-left: 5px;
      background: url(../../assets/images/chevron-up.svg) 0 0/8px no-repeat;
      width: 8px;
      height: 6px;
      .rtl & {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    &.desc:after {
      background: url(../../assets/images/chevron-down.svg) 0 0/8px no-repeat;
    }
  }

  // Collapse icon
  .collapse-icon {
    display: block;
    margin: 8px auto 0;
    width: 12px;
    height: 7px;
    background: url(../../assets/images/chevron-down.svg) 0 0/12px no-repeat;

    &[expanded='true'] {
      background: url(../../assets/images/chevron-up-yellow.svg) 0 0/12px no-repeat;
    }
  }
}

.table-style-2 {
  @extend .bg-color-1;
  thead {
    tr {
      th {
        padding: 0.75rem 0.5rem;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.75rem 0.5rem;
      }
    }
  }
}

.table-style-3 {
  thead {
    tr {
      th {
        padding: 0.75rem 0.5rem;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.75rem 0.5rem;
      }
    }
  }
}

.tr-collapse {
  background-color: $bg-color-4;
  .title {
    color: $body-color;
    font-weight: bold;
    font-size: 16px;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  thead {
    tr {
      th {
        padding-top: 0;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 0;

        &.title {
          width: 15%;
          white-space: nowrap;
        }
      }
    }
  }
}
