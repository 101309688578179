@import '../variables';
//////////////////////////////////////////
// Chart Tooltip
//////////////////////////////////////////
#chartjs-tooltip {
  transform: translate(-50%, -130%);
  .tooltip-inner {
    position: relative;
    padding: 10px 10px;
    border: 1px solid $body-color;
    background-color: $bg-color-1;

    .value {
      p {
        margin: 0 10px;
        font-size: 14px;
        font-weight: bold;
        color: $body-color;
        text-align: center;
        white-space: pre;
        line-height: 1;
      }
    }
    .label {
      p {
        margin: 0 10px;
        font-size: 12px;
        font-weight: 300;
        color: $body-color;
        text-align: center;
        white-space: pre;
      }
    }

    .caret {
      position: absolute;
      width: 0;
      height: 0;
      display: inline-block;
      border: 7px solid transparent;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 6px solid transparent;
      }

      &.down {
        right: calc(50% - 7px);
        bottom: -14px;
        border-top-color: $body-color;
        &::before {
          top: -8px;
          right: -6px;
          border-top-color: $bg-color-1;
        }
      }
      &.right {
        top: calc(50% - 7px);
        right: -14px;
        border-left-color: $body-color;
        &::before {
          top: -6px;
          right: -4px;
          border-left-color: $bg-color-1;
        }
      }
      &.up {
        top: -14px;
        right: calc(50% - 7px);
        border-bottom-color: $body-color;
        &::before {
          top: -4px;
          right: -6px;
          border-bottom-color: $bg-color-1;
        }
      }
      &.left {
        top: calc(50% - 7px);
        left: -14px;
        border-right-color: $body-color;
        &::before {
          top: -6px;
          left: -4px;
          border-right-color: $bg-color-1;
        }
      }
    }
  }
}
