@import '../variables';

//////////////////////////////////////////
// Home page
//////////////////////////////////////////
.home-page {
  .welcome-text {
    margin-top: 45px;
    margin-bottom: 40px;
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.53px;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
      font-size: 32px;
    }
  }

  .cover-image {
    margin-bottom: 58px;
    width: 100%;
    height: 472px;
    object-fit: cover;
    object-position: center;
    @include media-breakpoint-down(lg) {
      height: 282px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }
  }

  .link-box {
    margin-bottom: 28px;
    padding: 45px 1rem 1rem;
    height: 190px;

    color: $body-color;
    text-align: center;
    background-color: #0d1524;

    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }

    .icon {
      width: 24px;
    }
    .title {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: -0.38px;
    }
    .description {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.18px;
    }
  }
}
