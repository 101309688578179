@import '../variables';

@mixin rtl {
  @at-root [dir='rtl'] #{&} {
    @content;
  }
}

$ng-select-highlight: $bg-color-4 !default;
$ng-select-primary-text: $input-color !default;
$ng-select-disabled-text: $input-placeholder-color !default;
$ng-select-border: $input-border-color !default;
$ng-select-border-focused: $input-focus-border-color;
$ng-select-border-radius: 0px !default;
$ng-select-bg: $input-bg !default;
$ng-select-bg-focused: $input-focus-bg;
$ng-select-selected: lighten($ng-select-highlight, 10) !default;
$ng-select-marked: $bg-color-4 !default;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: $input-placeholder-color !default;
$ng-select-height: calc(1.5em + 1rem + 2px) !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;

.ng-select {
  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg-focused;
      border-color: $ng-select-border-focused $ng-select-border-focused $ng-select-border-focused;
      &:hover {
        box-shadow: none;
      }
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent darken($ng-select-border, 20);
        border-width: 0 5px 5px;
        transform: rotate(180deg);
        margin-top: 0;
        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-border-focused;
      //box-shadow: $ng-select-box-shadow;
      background-color: $ng-select-bg-focused;
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      color: $ng-select-disabled-text;
      background-color: $bg-color-3;
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }
      .ng-placeholder {
        color: $ng-select-placeholder;
      }
      .ng-input {
        input {
          font-size: $ng-select-value-font-size;
          color: $ng-select-primary-text;
        }
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;
      .ng-value-container {
        .ng-input {
          top: 8px;
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
        @include rtl {
          padding-right: 7px;
          padding-left: 0;
        }
        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          background-color: $ng-select-selected;
          border-radius: 2px;
          margin-right: 5px;
          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }
          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }
          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;
            &:hover {
              background-color: darken($ng-select-selected, 5);
            }
            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 1px solid darken($ng-select-selected, 10);
                border-right: none;
              }
            }
            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 0;
                border-right: 1px solid darken($ng-select-selected, 10);
              }
            }
          }
        }
        .ng-input {
          padding: 0 0 3px 3px;
          @include rtl {
            padding: 0 3px 3px 0;
          }
        }
        .ng-placeholder {
          top: 10px;
          padding-bottom: 5px;
          padding-left: 3px;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    color: $icons-color;
    &:hover .ng-clear {
      color: $danger;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    @include rtl {
      padding-left: 5px;
      padding-right: 0;
    }
    &:hover {
      .ng-arrow {
        border-top-color: darken($ng-select-border, 40);
      }
    }
    .ng-arrow {
      // border-color: darken($ng-select-border, 20) transparent transparent;
      // border-style: solid;
      // border-width: 5px 5px 2.5px;
      margin-top: 10px;
      width: 10px !important;
      height: 10px !important;
      background-image: url(../../assets/images/chevron-down.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      padding-right: calc(1.5em + 1rem);
      border-color: $danger;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e84a50' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e84a50' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.25rem) center;
      background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
      .ng-value-container {
        padding-right: 0;
      }
    }
    .ng-dropdown-panel {
      border-color: $danger;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg-focused;
  border: 1px solid $ng-select-border-focused;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: $ng-select-border;
    margin-top: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: $ng-select-border-radius;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }
    .ng-option {
      background-color: $ng-select-bg-focused;
      color: $input-color;
      border: 1px solid $ng-select-border;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding: 8px 10px;
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-selected;
        .ng-option-label {
          font-weight: 600;
        }
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }
      &.ng-option-disabled {
        color: lighten($ng-select-primary-text, 60);
      }
      &.ng-option-child {
        padding-left: 22px;
        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
