/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Simpler';
  font-style: normal;
  font-weight: normal;
  src: local('Simpler Regular'), url('Simpler-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-style: normal;
  font-weight: bold;
  src: local('Simpler Bold'), url('Simpler-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-style: normal;
  font-weight: 300;
  src: local('Simpler Light'), url('Simpler-Light.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-style: normal;
  font-weight: 900;
  src: local('Simpler Black'), url('Simpler-Black.woff') format('woff');
}
