//////////////////////////////////////////
// Imports
//////////////////////////////////////////
@import 'variables';
@import 'bootstrap/bootstrap-rtl';
@import 'assets/fonts/simpler/style.css';
@import 'swiper/css/bundle';
//@import "~@ng-select/ng-select/themes/default.theme.css";
//////////////////////////////////////////
// Components
//////////////////////////////////////////
@import 'components/sidebar';
@import 'components/page-covers';
@import 'components/dropdowns';
@import 'components/ng-select-theme';
@import 'components/buttons';
@import 'components/forms';
@import 'components/search-bar';
@import 'components/stats-boxes';
@import 'components/tabs';
@import 'components/tables';
@import 'components/gallery';
@import 'components/modals';
@import 'components/single-file-uploader';
@import 'components/ngx_daterange_picker';
@import 'components/chart-tooltip';

//////////////////////////////////////////
// Pages
//////////////////////////////////////////
@import 'pages/login-page';
@import 'pages/home-page';
@import 'pages/product-page';
@import 'pages/settings-page';

//////////////////////////////////////////
// HTML
//////////////////////////////////////////
html {
  height: 100%;
}

//////////////////////////////////////////
// Body
//////////////////////////////////////////
body {
  height: 100%;
  font-family: 'Simpler', Arial, Helvetica, sans-serif;
  background-image: linear-gradient(#0f1623 #{$header-height}, #000000);
  background-repeat: no-repeat;
  //background-attachment: fixed;

  app-root {
    display: block;
    height: 100%;

    .main-wrapper {
      height: 100%;

      // Login
      app-auth-layout {
        display: block;
        height: 100%;

        app-login {
          display: block;
          height: 100%;
        }
      }

      // Main app
      app-app-layout {
        display: block;
        height: 100%;
      }
    }
  }
}

// Main
main {
  display: block;
  padding-top: $header-height; /* Space for fixed navbar */
  padding-bottom: 30px;
  min-height: calc(100vh - #{$footer-height}); // Push the footer to bottom of the page

  @include media-breakpoint-down(lg) {
    margin-left: 56px; // offset sidebar
    .rtl & {
      margin-left: 0;
      margin-right: 56px;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: $header-height-mobile; /* Space for fixed navbar */
    margin-left: 0;
    .rtl & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  & > * {
    height: 100%;
    & > .spinner-container {
      $main-inner-height: $footer-height + $header-height + 30 /* <main> bottom padding */;
      height: calc(100vh - #{$main-inner-height}); // Push the footer to bottom of the page;
    }
  }
}

.tooltipWithLineBreaks {
  white-space: pre-line;
}

#edit-cron-button {
  margin-left: 8px;
}

#edit-cron-div {
  display: flex;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #39414e;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border-right: 2px solid #a2a2a2;
  border-left: 0px;
  border-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #e1e1e1;
}

//////////////////////////////////////////
// Header
//////////////////////////////////////////
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 10px 0 10px;
  font-size: 18px;
  background-color: #0f1623;

  @include media-breakpoint-down(md) {
    height: $header-height-mobile;
  }

  .header-col {
    flex-basis: calc(100% / 3);
    @include media-breakpoint-up(lg) {
      &:first-child {
        //margin-right: 56px;
        .rtl & {
          //margin-right: 0;
          //margin-left: 56px;
        }
      }
    }
  }

  .logo-link {
    img {
      width: 124px;
      @include media-breakpoint-down(md) {
        height: 22px;
        width: auto;
      }
    }
  }

  .sidebar-toggler {
    padding: 0;
    background-color: transparent;
    border: 0;
    color: $body-color;
    outline: 0;
    vertical-align: middle;
    .toggler-icon {
      display: block;
      width: 36px;
      height: 36px;
      background-image: url(../assets/images/menu-icon.svg);
      background-repeat: no-repeat;
      background-size: 36px;
      background-position: center;
    }

    &.open {
      .toggler-icon {
        background-image: url(../assets/images/close-icon.svg);
      }
    }
  }

  .link {
    color: #939393;
    border: 0;
    background-color: transparent;
  }
}
//////////////////////////////////////////
// Sub header
//////////////////////////////////////////
nav.sub-header {
  position: sticky;
  top: $header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $sub-header-height;
  background-color: $bg-color-4;
  z-index: 1028; // Above content, below sidebar

  @include media-breakpoint-down(lg) {
    height: $sub-header-height-mobile;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .btn {
    margin: 0 5px;
    @include media-breakpoint-down(md) {
      min-width: 30vw;
    }
  }

  .btn-view-mode {
    &.active {
      img {
        display: none;
        &.active {
          display: inline-block;
        }
      }
    }

    img {
      &.active {
        display: none;
      }
    }
  }
}

//////////////////////////////////////////
// Footer
//////////////////////////////////////////
footer {
  position: relative;
  background-color: $bg-color-1;

  @include media-breakpoint-up(xl) {
    height: 305px;
  }

  @include media-breakpoint-down(lg) {
    margin-left: 56px; // offset sidebar
    padding: 0 16px;
    .rtl & {
      margin-left: 0;
      margin-right: 56px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
    padding-bottom: 48px;
    .rtl & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .footer-upper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 35px;
    border-bottom: 1px solid #1a2b48;
    .logo {
      img {
        padding-bottom: 12px;
        @include media-breakpoint-down(lg) {
          width: 94px;
        }
      }
    }
    .social-links {
      .social-link {
        display: inline-block;
        margin: 0 0 6px;
        padding: 0;
        img {
          width: 36px;
        }
      }
    }
  }

  .footer-lower {
    display: flex;
    padding-top: 28px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .legal {
      @include media-breakpoint-up(lg) {
        font-size: 15px;
        flex: 0 0 135px;
        margin-right: 85px;
        .rtl & {
          margin-right: 0;
          margin-left: 85px;
        }
      }
      @include media-breakpoint-down(md) {
        font-size: 3.5vw;
        order: 2;
        text-align: center;
        color: #636f7e;
      }
    }

    .menu {
      flex: 1 0 auto;
      @include media-breakpoint-down(md) {
        order: 1;
      }
      ul {
        @include media-breakpoint-up(lg) {
          column-count: 4;
        }
        @include media-breakpoint-down(md) {
          column-count: 3;
        }

        li {
          margin-bottom: 0.5rem;
          line-height: 1;
          a {
            color: $body-color;
            font-size: 15px;
            @include media-breakpoint-down(md) {
              font-size: 3.5vw;
            }

            &.business-link {
              color: $pink;
              font-weight: bold;
            }

            &.logout {
              color: #636f7e;
            }
          }
        }
      }
    }
  }
}

//////////////////////////////////////////
// Misc
//////////////////////////////////////////
.bg-color-1 {
  background-color: $bg-color-1;
}

.bg-color-2 {
  background-color: $bg-color-2;
}

.border-style-1 {
  border-radius: 1px;
  border: solid 1px #2e5bff14;
}

.box {
  padding: 30px;
  @extend .bg-color-1;
  @include media-breakpoint-down(lg) {
    padding: 24px;
  }
  @include media-breakpoint-down(md) {
    padding: 12px;
  }

  .box-head {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .title {
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.38px;
      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
    }

    .sorting-bar {
      @include make-col(6);
      justify-content: space-around;

      @include media-breakpoint-down(lg) {
        @include make-col(5);
      }
      @include media-breakpoint-down(md) {
        @include make-col(12);
      }

      .btn-link {
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }
    }
  }
  h4 {
    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }
}

.sorting-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-link {
    @extend .py-0;
    text-decoration: none;
    font-size: 20px;
    letter-spacing: -0.25px;
    color: #b0bac9;

    &:hover {
      color: $brand-color-main;
    }
    &.active {
      color: $brand-color-main;
      font-weight: bold;
    }
  }
  .seperator {
    display: inline-block;
    height: 20px;
    width: 1px;
    background-color: #8798ad;
  }
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

//////////////////////////////////////////
// Swiper
//////////////////////////////////////////

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #1c2431;
  opacity: 1;

  &.swiper-pagination-bullet-active {
    background-color: $brand-color-main;
  }
}

.swiper-scrollbar {
  right: 0px;
  background-color: transparent;
  .rtl & {
    left: 0px;
    right: auto;
  }
}

//////////////////////////////////////////
// Circles
//////////////////////////////////////////
.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: solid 1px #979797;
  border-radius: 6px;
  vertical-align: middle; // Optimal, dont change

  &.active {
    border-color: $green;
    background-color: $green;
  }
  &.inactive {
    border-color: $red;
    background-color: $red;
  }
}

//////////////////////////////////////////
// Dropdown
//////////////////////////////////////////
.dropdown-menu {
  .rtl & {
    left: inherit !important;
  }
}
