@import '../variables';
//////////////////////////////////////////
// Stats boxes
//////////////////////////////////////////
.stats-box {
  height: 100%;
  background-color: $bg-color-1;

  h3 {
    margin-bottom: 0;
    font-size: 36px;
    font-weight: bold;
    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }
    @include media-breakpoint-down(md) {
      font-size: 8vw;
    }
  }

  p {
    margin-bottom: 0;
  }

  &.stats-box-1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include media-breakpoint-down(lg) {
      padding: 0 24px;
    }
    @include media-breakpoint-down(md) {
      padding: 0 12px;
    }

    .seperator {
      padding: 0;
      border-top: solid 1px #1a2b48;
    }

    .btn-tooltip {
      position: absolute;
      bottom: 6px;
      right: 16px;

      @include media-breakpoint-down(lg) {
        right: 6px;
      }
      @include media-breakpoint-down(md) {
        right: 0;
        bottom: 0;
      }

      .rtl & {
        right: auto;
        left: 16px;
        @include media-breakpoint-down(lg) {
          left: 6px;
        }
        @include media-breakpoint-down(md) {
          left: 0;
        }
      }
    }
  }

  &.stats-box-2 {
    padding: 30px;
    @include media-breakpoint-down(lg) {
      padding: 24px;
    }
    @include media-breakpoint-down(md) {
      padding: 12px;
    }

    p {
      font-weight: 300;
      &.parameter {
        font-size: 24px;
        font-weight: normal;
        @include media-breakpoint-down(md) {
          font-size: 6vw;
        }
      }
    }
    .seperator {
      padding: 0;
      margin: 30px 0 20px;
      border-top: solid 1px #1a2b48;
    }
    .sorting-bar {
      .btn {
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }
      .seperator {
        margin: 0;
      }
    }
  }

  &.stats-box-category {
    padding: 30px;
    @include media-breakpoint-down(lg) {
      padding: 24px;
    }
    @include media-breakpoint-down(md) {
      padding: 12px;
    }

    .head {
      display: flex;
      font-size: 20px;
      letter-spacing: -0.25px;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }

      span {
        flex: 1 1 100%;
      }

      .icon {
        margin-right: 10px;
        .rtl & {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }

    h3 {
      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
      @include media-breakpoint-down(md) {
        font-size: 6vw;
      }
    }

    p + p {
      color: #636f7e;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }

    .seperator {
      margin: 12px 0;
      border-top: solid 1px #1a2b48;
    }

    .stats-inner {
      display: flex;
      .stats-item {
        flex: 1 1 100%;
      }
    }

    .footer {
      display: flex;
      > div {
        flex: 1 1 100%;
      }
    }
  }

  &.stats-box-category-2 {
    @extend .stats-box-category;

    .head {
      h3 {
        font-size: 30px;
        @include media-breakpoint-down(lg) {
          font-size: 24px;
        }
      }
    }
  }

  .stat-arrow-up {
    content: '';
    display: inline-block;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: #0000 #0000 #00c88a #0000;
  }
  .stat-arrow-down {
    content: '';
    display: inline-block;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #e84a50 #0000 #0000 #0000;
  }
}
