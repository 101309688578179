@import '../variables';
//////////////////////////////////////////
// Settings page
//////////////////////////////////////////
.settings-page {
  .settings-container {
    padding: 30px;
    background-color: $bg-color-1;

    & + .settings-container {
      margin-top: 30px;
    }

    .btn-avatar {
      .avatar-image {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  h4 + p {
    font-size: 14px;
  }
}
