app-single-file-uploader {
  .dropzone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 275px;
    border-radius: 2px;
    border: solid 1px #1a2b48;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: center;
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }

    .svg-container {
      width: 100%;

      svg {
        max-width: 100%;
        max-height: 250px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .buttons-container {
    margin-top: 14px;
  }
}
