@import '../variables';

//////////////////////////////////////////
// Forms
//////////////////////////////////////////

.has-search {
  .form-control-feedback {
    display: block;
    position: absolute;
    margin-top: 12px;
    margin-left: 10px;
    width: 18px;
    height: 18px;
    pointer-events: none;
    z-index: 2;
    .rtl & {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .form-control {
    padding-left: 36px;
    .rtl & {
      padding-left: 0;
      padding-right: 36px;
    }
  }
}

select.form-control {
  option:hover {
    color: red;
  }
}

.form-control {
  &.is-date {
    position: relative;
    &::after {
      content: url(../../assets/images/chevron-down.svg);
      position: absolute;
      top: 50%;
      right: 10px;
      border: none;
      width: 10px;
      vertical-align: baseline;
      transform: translate(0, -50%);

      .rtl & {
        right: auto;
        left: 10px;
      }
    }
  }
  // NG invalid
  &.ng-invalid.ng-touched {
    @extend .is-invalid;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &.ltr-text {
    direction: ltr;
  }

  &.rtl-text {
    direction: rtl;
  }
}

// .custom-control-label {
//   &::before,
//   &::after {
//     top: 0.3rem;
//   }
// }

.input-group {
  .ng-select {
    flex: 1 1 auto;
  }
  .input-group-prepend {
    margin-right: 5px;
  }
}

//////////////////////////////////////////
// Color pikcer
//////////////////////////////////////////
.button-color-addon {
  padding: 0;
  border-right-color: transparent;
  .rtl & {
    border-right-color: $dark;
    border-left-color: transparent;
  }
  .color-box {
    display: block;
    height: 38px;
    width: 38px;
    overflow: hidden;

    img {
      margin: 1px;
      opacity: 0.5;
      transform: rotate(45deg);
    }
  }
}

color-picker {
  .color-picker {
    border-color: $border-color-1;
    background-color: $bg-color-2;

    .cursor {
      border: #d4d4d4 solid 2px;
    }

    .hex-text .box div {
      color: #fff;
    }

    .selected-color {
      border: 0;
    }
  }
}

//////////////////////////////////////////
// Datepicker
//////////////////////////////////////////
ngb-datepicker {
  border: 0 !important;

  .rtl & {
    left: auto !important;
  }

  // Header
  .ngb-dp-header {
    background-color: transparent;

    .rtl & {
      .ngb-dp-navigation-chevron {
        transform: rotate(45deg);
      }

      .right .ngb-dp-navigation-chevron {
        transform: rotate(-135deg);
      }
    }
  }

  // Weekdays
  .ngb-dp-weekdays {
    background-color: transparent;
  }

  .ngb-dp-day {
    &:focus {
      outline: 0;
    }
    .btn-light {
      color: $body-color;
      border-radius: 0;
      &:hover {
        background-color: $bg-color-4;
      }

      &.bg-primary,
      &.active {
        color: $body-color;
        background-color: $brand-color-main !important;
      }
    }
  }
}

ngb-datepicker-navigation-select > .custom-select {
  padding: 0;

  .rtl & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

//////////////////////////////////////////
// Time picker
//////////////////////////////////////////
ngb-timepicker {
  // Invalid state
  &.ng-invalid.ng-touched {
    .form-control {
      border-color: $danger;
    }
  }

  .ngb-tp {
    justify-content: center;
  }
}
